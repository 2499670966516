import { Controller } from "@hotwired/stimulus";
const lodash = require("lodash");
global = globalThis; // Need this for autocomplete.js to work with esbuild
const autocomplete = require("autocomplete.js");
const axios = require("axios");

export default class extends Controller {
  static values = { url: String };
  static targets = ["input", "hiddenInput"];
  connect() {
    autocomplete(
      this.inputTarget,
      { hint: false, autoselect: true, autoselectOnBlur: true },
      [
        {
          source: lodash.debounce((query, cb) => {
            axios
              .get(`${this.urlValue}${query}`, {
                headers: { Accept: "application/json" },
              })
              .then((response) => {
                cb(response.data);
              });
          }, 300),
          displayKey: "name",
          templates: {
            suggestion: (suggestion) => {
              return suggestion.name;
            },
          },
        },
      ]
    ).on("autocomplete:selected", (event, suggestion) => {
      if (this.hasHiddenInputTarget) {
        this.hiddenInputTarget.value = suggestion.id;
      }
    });
  }
}
